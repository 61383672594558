<template>
  <div class="drawer-mobile">
    <div class="profile-box d-flex align-items-center justify-content-end gap-4">
      <NuxtLink :to="`/profile/referral`" class="referral-container" v-if="!login && featuresStore.isShowReferral">
        <img src="./../../public/assets/imgs/referral.gif" alt="referral icon" />
      </NuxtLink>
      <span v-if="!login">
        <img src="~/assets/img/or_sympol.svg" />
      </span>
      <div v-if="!loginBox">
        <LocLink v-if="!login" to="/profile/notifications">
          <div class="notifications-icon" @click="testNotifcation">
            <i class="pi pi-bell p-overlay-badge" />
          </div>
        </LocLink>
      </div>
      <div class="drawer-mobile-bars" @click="openDrawerMobile">
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
      </div>
    </div>
  </div>
  <div :class="openSide ? '' : 'opne'" class="sideMenu d-flex flex-wrap">
    <div class="d-flex justify-content-between mb-3 w-100">
      <div class="logo-container">
        <LocLink to="/">
          <img alt="image" class="img-fluid" src="~/assets/img/logo_white.svg" />
        </LocLink>
      </div>
      <div class="close-item" @click="closeSide">
        <i class="pi pi-times"></i>
      </div>
    </div>
    <div class="content">
      <div class="nav-box">
        <ul class="list-unstyled">
          <li v-if="!login">
            <LocLink to="/profile">
              <LayoutProfileBox :isinSideBar="true" />
            </LocLink>
          </li>
          <li>
            <LocLink to="/why-acceptix">{{ $t('whystudentcenter') }}</LocLink>
          </li>
          <li>
            <a href="/#howWorkSection" @click="closeSide">{{ $t('howdowework') }}</a>
          </li>
          <li>
            <LocLink class="destinations-link" to="/destinations">{{ $t('destinations') }}</LocLink>
          </li>
          <li>
            <LocLink to="/search/university">{{ $t('findUniversities') }}</LocLink>
          </li>
          <li>
            <LocLink to="/search/institute">{{ $t('findEnglishSchools') }}</LocLink>
          </li>
          <li>
            <NuxtLink @click="switchLanguage">
              {{ $t('language') }} {{ otherLanguage }}
              <i class="pi pi-globe"></i>
            </NuxtLink>
          </li>
        </ul>
      </div>
      <div class="book-box d-flex justify-content-between gap-4 mt-4 align-items-center">
        <div class="social">
          <SocialMedia :whiteItem="true" class="header-socialMedia" />
        </div>
        <!-- <LocLink to="/book-appointment" @click="closeSide" class="bookAnAppointment">
          {{ $t("bookanappointment") }}</LocLink> -->
      </div>

      <div v-if="login" class="button-box pt-4 mt-4">
        <LocLink class="white-button bordered" to="/auth/signup">
          {{ $t('signup') }}
        </LocLink>
        <LocLink class="white-button mt-4" to="/auth/login">
          {{ $t('loginText') }}
        </LocLink>
      </div>
      <div v-else class="button-box pt-4 mt-4">
        <span class="white-button bordered" to="/auth/signup" @click="logoutFn">
          {{ $t('logout') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
const { locale, setLocale } = useI18n();
const nuxtApp = useNuxtApp();

const otherLanguage = computed(() => {
  return locale.value === 'en' ? 'AR' : 'EN';
});

function switchLanguage() {
  setLocale(otherLanguage.value.toLowerCase());
  nuxtApp.$store.dispatch('countries/resetGetCountriesRegistration');
  setTimeout(() => {
    window.location.reload();
  }, 500);
}
</script>

<script>
import { useAxios } from '@/composables/useAxios';
import destinationLink from '@/mixins/destinationLink';
import { useFeaturesStore } from '../../store/features.js';

export default {
  mixins: [destinationLink],
  props: ['login', 'loginBox'],
  data: () => ({
    openSide: true,
    loginBox: true,
    login: true,
    featuresStore: useFeaturesStore(),
  }),
  mounted() {
    setTimeout(() => {
      this.loginBox = false;
    }, 100);
    if (localStorage.getItem('userlogin')) {
      this.login = false;
    }
  },
  methods: {
    openDrawerMobile() {
      this.openSide = false;
      // if (process.client) {
      //   document.body.className = "overflow-hidden";
      // }
    },
    closeSide() {
      this.openSide = true;
      if (process.client) {
        document.body.className = '';
      }
    },
    async logoutFn() {
      this.$store.state.functions.profileMenu = false;
      // localStorage.removeItem("token");
      const token = useCookie('token');
      localStorage.removeItem('email');
      localStorage.removeItem('userlogin');
      // this.closeBox();
      const { api } = useAxios();
      await api.get('/auth/logout').then(res => {
        this.$store.state.login.profileMenu = false;
        this.$store.state.login.logout = true;
        this.$store.dispatch('profile/removeProfile');
        this.$router.push({
          path: this.localePath('/'),
        });
        token.value = null;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bookAnAppointment {
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  text-decoration: underline !important;
}

.img-box {
  img {
    height: 38px;
    width: 38px;
    object-fit: cover;
    border-radius: 50%;
  }
}

.destinations {
  .rotate-180 {
    transform: rotate(180deg);
  }

  .li-container {
    border-bottom: 1px solid #cccfd1;
  }

  .destinations-link {
    border: none !important;
  }

  button {
    all: unset;
    cursor: pointer;
  }

  .destinations-box {
    height: 0;
    overflow: hidden;
    transition: height 0.2s ease-in-out;
    background-color: #414ddc;
  }

  .destinations-is-show {
    height: 207px;
  }
}

.drawer-mobile {
  display: none;

  .referral-container {
    img {
      width: 35px;
    }
  }

  @include dir(ltr) {
    margin-left: auto;
  }

  @include dir(rtl) {
    margin-right: auto;
  }

  @include media(992) {
    & {
      display: block;
    }
  }

  @include hover(0.2);
  width: fit-content;
  cursor: pointer;

  .drawer-mobile-bars {
    &:hover {
      .bar {
        background-color: $color-secondary;
      }
    }

    &:active {
      transform: translateY(4px) scale(0.98);
    }
  }

  .bar {
    background-color: $color-primary;
    width: 36px;
    height: 4px;
    border-radius: 50px;
    margin-bottom: 6px;
    @include hover(0.2);

    &:last-of-type {
      width: 20px;
    }
  }
}

.sideMenu {
  overflow: auto;
  position: fixed;
  inset: 0;
  background-color: $color-primary-light;
  z-index: 99999;
  padding: 40px;
  display: flex;
  // align-items: center;
  justify-content: center;
  @include trans(0.2);
  opacity: 0;
  visibility: hidden;

  &.opne {
    opacity: 1;
    visibility: visible;
  }

  .close-item {
    // position: absolute;
    // top: 30px;
    // right: 20px;
    @include trans(0.2);

    &:active {
      transform: translateY(4px) scale(0.98);
    }

    i {
      cursor: pointer;
      font-size: 33px;
      color: #fff;
    }
  }

  .content {
    width: 100%;

    .nav-box {
      li {
        a {
          display: block;
          width: 100%;
          padding: 22px 10px;
          border-bottom: 1px solid #cccfd1;
          color: #fff;

          i {
            color: rgba(#cccfd1, 0.9);
          }

          @include hover(0.2) {
            background-color: rgba(#cccfd1, 0.08);
          }
        }
      }
    }
  }

  .white-button {
    color: #fff;
    width: 100%;
    display: block;
    padding: 12px;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;

    &.bordered {
      border: 1px solid #fff;
    }

    &:hover {
      background-color: rgba(#cccfd1, 0.08);
      color: $color-white;
    }
  }
}

.notifications-icon {
  i {
    font-size: 32px;
    color: $color-primary;
  }
}
</style>
