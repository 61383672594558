<template>
  <div class="lang-box">
    <div class="content d-flex">
      <NuxtLink @click="switchLanguage"><i class="pi pi-globe"></i> {{ otherLanguage }}</NuxtLink>
    </div>
  </div>
</template>

<script setup>
const { locale, setLocale } = useI18n();
const nuxtApp = useNuxtApp();

const otherLanguage = computed(() => {
  return locale.value === 'en' ? 'AR' : 'EN';
});

function switchLanguage() {
  setLocale(otherLanguage.value.toLowerCase());
  nuxtApp.$store.dispatch('countries/resetGetCountriesRegistration');
  setTimeout(() => {
    window.location.reload();
  }, 500);
}
</script>

<style lang="scss" scoped>
.lang-box {
  display: flex;
  justify-content: flex-end;

  .content {
    a {
      width: fit-content;
      cursor: pointer;
      font-size: 12px;
      display: flex;
      align-items: center;
      gap: 4px;
      @include colorPrimary;

      i {
        font-size: 22px;
      }
    }
  }
}
</style>
